<template>
    <div class="products-wrapper">
        <DashboardPopularProductions />
        <DashboardDiscontinuedProd />
    </div>
</template>
<script>
import DashboardDiscontinuedProd from '@/containers/home/DashboardDiscontinuedProd.vue'
import DashboardPopularProductions from '@/containers/home/DashboardPopularProductions.vue'

export default {
    name: 'DashboardProducts',
    components: { DashboardDiscontinuedProd, DashboardPopularProductions }
}
</script>
<style lang="scss" scoped>
.products-wrapper {
    display: flex;
    //TODO 14.5使用者上升後使用
    // gap: 10px;

    div,
    section {
        width: 100%;
    }

    @media screen and (max-width: 1017px) {
        flex-direction: column;
    }
    //TODO 14.5使用者上升後移除
    :deep .popular_productions-container {
        margin-right: 10px;
        @media screen and (max-width: 1017px) {
            margin-bottom: 10px;
        }
    }
}
</style>
